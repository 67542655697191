import React from "react"
import { Link } from "gatsby"
import "./layout.css"

import styled from "styled-components"

export default function Header(props) {
  if (props.locationType == "home") {
    return (
      <HeaderContainer>
        <div className="header-inner">
          <div>NUCLEOTYPE</div>
        </div>
      </HeaderContainer>
    )
  }
  return (
    <HeaderContainer>
      <div className="header-inner">
        <div>NUCLEOTYPE</div>
      </div>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  .header-inner {
    max-width: 1200px;
    margin: auto;
  }
  .gray {
    color: gray;
  }
  .black {
    color: black;
  }
  @media (max-width: 768px) {
    .header-inner {
      font-size: 18px;
    }
  }
`
