import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/customlayout"
import SEO from "../../../components/seo"
import AirwayHeader from "../../../components/airwayheader"

import styled from "styled-components"

export default function ChoosingInduction(props) {
  return (
    <>
      <AirwayHeader></AirwayHeader>
      <AirwayContainer>
        <Layout
          title={"Choosing an Induction Agent for Rapid Sequence Intubation"}
        >
          <SEO
            title={"Choosing an Induction Agent for Rapid Sequence Intubation"}
            description={
              "Induction agents used in rapid sequence intubation all lead to a similar endpoint which includes providing sedation and amnesia and can improve intubating conditions."
            }
            url={"https://www.nucleotype.com/airway/choosing-induction-agent"}
          />

          <p>
            Induction agents used in rapid sequence intubation all lead to a
            similar endpoint which includes providing sedation and amnesia and
            can improve intubating conditions. But small pharmacologic
            differences between these drugs may offer advantages over another in
            certain clinical scenarios.
          </p>

          <h2>Head injury or stroke</h2>
          <p>
            The drug of choice in cases where elevated intracranial pressure
            (ICP) is suspect, whether from traumatic head injury or other
            conditions, is etomidate.
          </p>
          <p>
            Ketamine is generally avoided because it is thought to potentially
            increase ICP through it’s sympathetic stimulation. Although evidence
            suggesting that ketamine increases intracranial pressure is weak.
          </p>
          <p>
            Other agents such as propofol and midazolam have been used in cases
            of elevated ICP, however, because of their relatively increased risk
            of hypotension compared to etomidate, their use should be considered
            carefully because of the risk of hypotension induced brain injury.
          </p>

          <h2>Status Epilepticus</h2>
          <p>
            Propofol and midazolam are two induction agents commonly used in RSI
            that have anticonvulsive properties and as a result, recommended in
            the use in status epilepticus requiring intubation. Keep in mind
            that both propofol and midazolam can cause drops in blood pressure
            resulting in hypotension. For example, the administration of routine
            doses for midazolam in RSI (0.2 mg/kg) has been shown to have an
            average drop in mean arterial blood pressure in healthy patients of
            10 to 25 percent. Because of the hemodynamic profile for both
            propofol and midazolam to cause decreases in mean arterial pressure,
            their use in status epilepticus when the patient develops
            hemodynamic compromise is limited.
          </p>
          <p>
            When blood pressure or shock is of concern in the setting of status
            epilepticus, etomidate can be used because of its hemodynamic
            stability, although it has a slightly higher rate of EEG-documented
            seizure activity compared with other medications.
          </p>
          <p>
            Ketamine should not be used in status epilepticus because of its
            stimulant effects.
          </p>

          <h2>Reactive Airway Disease</h2>
          <p>
            For hemodynamically stable patients with severe bronchospasm
            requiring intubation, ketamine, or propofol are recommended because
            of their bronchodilatory properties.
          </p>
          <p>
            In hypotensive patients, ketamine or etomidate are preferred because
            of their favorable hemodynamic profiles.
          </p>

          <h2>Cardiovascular disease</h2>
          <p>
            Because of its hemodynamic stability and absence of induced
            hypertension, etomidate is the recommended induction agent for
            patients with significant cardiovascular disease.
          </p>
          <p>
            Pretreatment with fentanyl, may also be considered, especially in
            patients with coronary artery disease or in cases of suspected
            aortic dissection, in order to mitigate the catecholamine release
            associated with laryngoscopy and intubation.
          </p>

          <h2>Shock</h2>
          <p>
            Although all induction agents, commonly used in RSI, can cause some
            degree of hypotension, ketamine and etomidate, are the recommended
            agents in cases of severe hypotension or shock because of their
            favorable cardiovascular profiles. Ketamine causes a sympathetic
            surge that may sustain blood pressure, however both ketamine and
            etomidate have still been shown to cause small drops in mean
            arterial pressure in patients with severe hypotension, but less than
            other agents.
          </p>
          <StyledLine top />
          <div className="recommended">
            <div className="recommended-heading">RECOMMENDED READING</div>
            <RecommendedArticleContainer to="airway/rsi-indications">
              <div className="content-label">ARTICLE</div>
              <h2>
                Indications and Contraindications to Rapid Sequence Intubation
              </h2>
              <p>
                Indications for RSI are simply those patients who cannot
                ventilate, oxygenate or fail to protect their aiway.
                Contraindications are few and relative.
              </p>
            </RecommendedArticleContainer>
            <RecommendedArticleAnchor href="https://amzn.to/2WEky5N">
              <div className="content-label">AFFILIATE LINK</div>
              <h2>The ICU Book</h2>
              <p>
                A fundamental and respected resource in critical care. Including
                a review of various common critical care pathology, ariway and
                mechnical ventilation.
              </p>
            </RecommendedArticleAnchor>
            <RecommendedArticleContainer to="airway/rsi-process">
              <div className="content-label">ARTICLE</div>
              <h2>
                Seven P's of RSI - The Process of Rapid Sequence Intubation
              </h2>
              <p>
                RSI involves the use of an induction agent, followed immediately
                by a neuromuscular blocking agent to quickly create an optimal
                environment for intubation.
              </p>
            </RecommendedArticleContainer>
          </div>
          <StyledLine />
        </Layout>
      </AirwayContainer>
    </>
  )
}

const AirwayContainer = styled.div`
  padding: 0 15px 15px 15px;
  max-width: 1000px;
  margin: auto;
  h3 {
    margin-top: 40px;
  }
  .recommended {
    .recommended-heading {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 35px;
      font-weight: 500;
      font-family: futura-pt, sans-serif;
    }
    .content-label {
      font-family: futura-pt, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      color: gray;
    }
  }

`

const RecommendedArticleAnchor = styled.a`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const RecommendedArticleContainer = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const StyledLine = styled.hr`
  margin-top: ${props => (props.top ? "100px" : "50px")};
`
