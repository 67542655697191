import React from "react"
import { Link } from "gatsby"
import Header from "./Header"
import "./layout.css"

import styled from "styled-components"

class Layout extends React.Component {
  render() {
    const { title, children } = this.props
    let header

    return (
      <LayoutContainer>
        <div className="inner-container">
          <header>
            <h1>{title}</h1>
          </header>
          <main>{children}</main>
        </div>
      </LayoutContainer>
    )
  }
}

export default Layout

const LayoutContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding: 2.625rem 1.3125rem;
  position: relative;
  header {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 15px;
    @media (max-width: 768px) {
      margin-bottom: 35px;
      width: 100%;
      left: 0;
      right: 0;
      margin-left: 0;
      margin-right: 0;
      margin-top: 35px;
      margin-bottom: 35px;
      padding: 15px 0;
    }
  }
  h1 {
    font-size: 44px;
    text-align: center;
    max-width: 900px;
    margin: auto;
    margin-top: 1.75rem;
    margin-bottom: 0px;
    font-family: adobe-garamond-pro, serif;
    line-height: 110%;
    @media (max-width: 768px) {
      font-size: 32px;
      text-align: left;
    }
  }
  h2 {
      margin-top: 3.5rem;
  }
`

const RecommendedArticleAnchor = styled.a`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const RecommendedArticleContainer = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`
