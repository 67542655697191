import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"

export default function AirwayHeader(props) {
  return (
    <HeaderContainer>
      <div className="inner">
        <Link to={"/"}>NUCLEOTYPE</Link>
        <span>|</span>
        <Link to={"/airway"}>
          <div className="section">AIRWAY</div>
        </Link>
      </div>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: #ffffff;
  z-index: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  .inner {
    max-width: 1100px;
    margin: auto;
    font-family: futura-pt, sans-serif;
  }
  .section {
    font-weight: 700;
  }
  a {
    text-decoration: none;
    color: black;
    display: inline-block;
    box-shadow: none;
  }
  span {
      padding: 0 10px;
      color: lightgray;
  }
`
